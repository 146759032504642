import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';

function ActivityNavigation({ data, prevActivity, nextActivity }) {
	const { nodes } = data.allMarkdownRemark;

	const newPrevActivity = nodes.find((n) => n.frontmatter.title === prevActivity);
	const newNextActivity = nodes.find((n) => n.frontmatter.title === nextActivity);

	return (
		<>
			<Link to={`${newPrevActivity.fields.slug}`} title={newPrevActivity.frontmatter.title}>
				&#x3008; {newPrevActivity.frontmatter.title}
			</Link>
			<Link to={`${newNextActivity.fields.slug}`} title={newNextActivity.frontmatter.title}>
				{newNextActivity.frontmatter.title} &#x3009;
			</Link>
		</>
	);
}

export default (props) => (
	<StaticQuery
		query={graphql`
			query ActivityNavigation {
				allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "activity-page" } } }) {
					nodes {
						frontmatter {
							title
						}
						fields {
							slug
						}
					}
				}
			}
		`}
		render={(data) => <ActivityNavigation data={data} {...props} />}
	/>
);
