import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import MakeHtml from '../components/commons/MakeHtml';
import slugify from 'react-slugify';
import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';
import ActivityNavigation from '../components/navigations/ActivityNavigation';

export function ActivityPageTemplate({
	title,
	introductionPage,
	cover,
	contents,
	prevActivity,
	nextActivity,
}) {
	return (
		<article>
			<ParallaxProvider>
				<ParallaxBanner
					className="your-class"
					layers={[
						{
							image: `${
								!!cover.image.childImageSharp ? cover.image.childImageSharp.fluid.src : cover.image
							}`,
							amount: cover.parallaxSpeed,
							props: {
								style: { backgroundPosition: `${cover.positionnementImage}` },
							},
						},
					]}
					style={{
						height: '100%',
						padding: '80px 0',
					}}
				>
					{/* <div
				className="classical-full-img"
				style={{
					backgroundImage: `url(${
						!!cover.image.childImageSharp ? cover.image.childImageSharp.fluid.src : cover.image
					})`,
				}}
			> */}
					{introductionPage && introductionPage !== '' && (
						<div className="medium-contain classical-text-in-full-img-wrapper">
							<section className="classical-text-in-full-img">
								<h1>{title}</h1>
								<MakeHtml text={introductionPage} />
							</section>
						</div>
					)}
					{/* </div> */}
				</ParallaxBanner>
			</ParallaxProvider>
			{contents && (
				<section className="small-contain">
					{(!introductionPage || introductionPage === '') && <h1>{title}</h1>}
					{contents.map(({ contentImage, body, placement, style }, index) => (
						<div
							className={`activity-bloc-wrapper ${placement} ${style}`}
							key={`activity_bloc_${index}`}
						>
							{contentImage && contentImage.image && contentImage.altImage !== '' && (
								<picture>
									<source
										srcSet={
											!!contentImage.image.childImageSharp && cover.image.childImageSharp !== ''
												? contentImage.image.childImageSharp.fluid.srcWebp
												: contentImage.image
										}
										type="image/webp"
									/>
									<img
										src={
											!!contentImage.image.childImageSharp && cover.image.childImageSharp !== ''
												? contentImage.image.childImageSharp.fluid.src
												: contentImage.image
										}
										alt={contentImage.altImage}
										loading="lazy"
									/>
								</picture>
							)}
							{body && <MakeHtml text={body} />}
						</div>
					))}
				</section>
			)}
			{prevActivity && nextActivity && (
				<div className="medium-contain chage-article-buttons-action-wrapper">
					<ActivityNavigation prevActivity={prevActivity} nextActivity={nextActivity} />
				</div>
			)}
			<style jsx>
				{`
					.classical-full-img {
						background-position: ${cover.positionnementImage};
					}
					.classical-text-in-full-img-wrapper {
						display: flex;
						justify-content: ${cover.positionnementBloc};
					}
				`}
			</style>
		</article>
	);
}

export default function ActivityPage({ data }) {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout title={frontmatter.seo.seoTitle} description={frontmatter.seo.seoDescription}>
			<ActivityPageTemplate
				title={frontmatter.title}
				introductionPage={frontmatter.introductionPage}
				cover={frontmatter.activityCover}
				contents={frontmatter.contents}
				prevActivity={frontmatter.prevActivity}
				nextActivity={frontmatter.nextActivity}
			/>
		</Layout>
	);
}

export const pageQuery = graphql`
	query ActivityPageById($id: String!) {
		markdownRemark(id: { eq: $id }) {
			id
			frontmatter {
				title
				introductionPage
				activityCover {
					altImage
					image {
						childImageSharp {
							fluid(maxWidth: 1922, quality: 90) {
								...GatsbyImageSharpFluid
							}
						}
					}
					positionnementImage
					positionnementBloc
					parallaxSpeed
				}
				contents {
					body
					placement
					style
					contentImage {
						altImage
						image {
							childImageSharp {
								fluid(maxWidth: 1300, quality: 90) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
				prevActivity
				nextActivity
				seo {
					seoTitle
					seoDescription
				}
			}
			fields {
				slug
			}
		}
	}
`;
